
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

html,
body,
#root {
  /* width: 100%;
  height: 100%; */
  /* z-index: 0; */
  /* position: absolute; */

  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */

}

body {
  font-family: 'Inter var', sans-serif;
  color: white;
  user-select: none !important;
  /* cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxMCIgZmlsbD0id2hpdGUiLz48L3N2Zz4='),
    auto; */
}

button {
  height: 32px;
  min-width: 100px;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: white;
  border: 1px solid transparent;
  border-bottom: 3px solid #a0a0a0;
  border-radius: 3px;
  padding: 8px 24px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: auto;
  height: 45px;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* width: 100vw;
  height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 1s;
}
.stack {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #606060;
}

.bg {
  background: #252525;
}


.fullscreen.notready {
  color: #606060;
}

.fullscreen.loaded > div b {
  cursor: pointer;
}

.fullscreen.clicked {
  pointer-events: none;
  opacity: 0;
}

.pmndrs-menu,
.pmndrs-menu > div b {
  color: #303030;
}

.pmndrs-menu svg {
  fill: #303030;
} 

.music2 {
  color: black
 /* width: 0, */
}


