.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 15px;
    /* position:absolute; */
    /* z-index: 1; */
    /* align-items: center; */
    /* width: 30%; */

}
.page{
    position: absolute;
    z-index: 1;
 }  

/* .canvas{
    z-index: 2
} */
.box2 {
    /* width: 80px; */
    /* height: 40px; */
    background: "#1A1B1E";
    /* padding: 10px 10px; */
    position: relative;
    border-radius: 15px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.01);
  

  }

  /* Inigo palette
    #FFE7A6
    #863703
    #1E0108
    #001760
    #63B4EE
    #FFEBB0
   */

  .box2::after {
    position: absolute;
    content: "";
    /* top: 15px; */
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transform: scale(0.9) translateZ(0);
    filter: blur(10px);
    background: linear-gradient(
      to left,
      /* #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770 */
      #FFE7A6,
      #863703,
      #1E0108,
      #001760,
      #63B4EE,
      #FFEBB0,
      #63B4EE,
      #001760,
      #1E0108,
      #863703,
      #FFE7A6
    );
    background-size: 200% 200%;
    animation: animateGlow 6s ease-in-out infinite  ;
  }

  .box2:hover{
    box-shadow: 0 0 0 1px rgba(0,0,0,.01);
    transform: scale(1.01) translateZ(0);

  }
  .box2:hover::after{
    box-shadow: 0 0 0 1px rgba(0,0,0,.01);
    transform: scale(1.05) translateZ(0);
    /* filter: blur(10px); */
    /* background: linear-gradient(
        to left,
        #ff5770,
        #e4428d,
        #c42da8,
        #9e16c3,
        #6501de,
        #9e16c3,
        #c42da8,
        #e4428d,
        #ff5770
      );
    background-size: 200% 200%; */
    animation: animateGlow 2.75s linear infinite  ;
  }
  
  @keyframes animateGlow {
    0% {
      background-position: 0% 50%;
    }
   
    100% {
      background-position: 200% 50%;
    }
  }

  .box3 {
    /* width: 80px; */
    /* height: 40px; */
    background: "#1A1B1E";
    /* padding: 10px 10px; */
    position: relative;
    border-radius: 15px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.01);
  
  }
  .box3::after {
    position: absolute;
    content: "";
    top: 15px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 80%;
    width: 100%;
    transform: scale(0.9) translateZ(0);
    filter: blur(10px);
    background: linear-gradient(
      to left,
      /* #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770 */
      #FFE7A6,
      #863703,
      #1E0108,
      #001760,
      #63B4EE,
      #FFEBB0,
      #63B4EE,
      #001760,
      #1E0108,
      #863703,
      #FFE7A6
    );
    background-size: 200% 200%;
    animation: animateGlow2 11s linear infinite  ;
  }
  .box3:hover{
    box-shadow: 0 0 0 1px rgba(0,0,0,.01);
    transform: scale(1.0005) translateZ(0);

  }
  /* .box3:hover::after{ */
    /* box-shadow: 0 0 0 1px rgba(0,0,0,.01); */
    /* transform: scale(1.005) translateZ(0); */
    /* filter: blur(10px); */
    /* background: linear-gradient(
        to left,
        #ff5770,
        #e4428d,
        #c42da8,
        #9e16c3,
        #6501de,
        #9e16c3,
        #c42da8,
        #e4428d,
        #ff5770
      );
    background-size: 200% 200%; */
    /* animation: animateGlow2 9s linear infinite  ;
  } */

  @keyframes animateGlow2 {
    0% {
      background-position: 200% 50%;
    }
   
    100% {
      background-position: 0% 50%;
    }
  }

  .linearWipe {
    text-align: center;
    background: linear-gradient(to right,
    #FFE7A6 20%,
    #863703 40%,
    #1E0108 60%,
    #001760 80%
    /* #63B4EE,
    #FFEBB0, */
     /* #FFF 20%, #FF0 40%, #FF0 60%, #FFF 80% */
     );
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    animation: shine 5s linear infinite;
 
  }
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
  .gradientText {
	background: linear-gradient(-45deg,    
     #FFE7A6,
    #863703,
    #1E0108,
    #001760,
    #63B4EE,
    #FFEBB0);
	background-size: 200%;
	/* font-family: Arial, Helvetica, sans-serif; */
	font-weight: 900;
	font-size: 5.5vw;
	letter-spacing: -1px;
	/* text-transform: uppercase; */
	-webkit-background-clip: text;
  background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: animated_text 10s ease-in-out infinite;
	-moz-animation: animated_text 10s ease-in-out infinite;
	-webkit-animation: animated_text 10s ease-in-out infinite;
}
.gradientText10 {
	/* background: linear-gradient(-45deg,    
     #FFE7A6,
    #863703,
    #1E0108,
    #001760,
    #63B4EE,
    #FFEBB0);
	background-size: 200%; */
	/* font-family: Arial, Helvetica, sans-serif; */
	font-weight: 900;
	font-size: 12;
	letter-spacing: -1px;
  color: white;
	/* text-transform: uppercase; */
	/* -webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: animated_text 10s ease-in-out infinite;
	-moz-animation: animated_text 10s ease-in-out infinite;
	-webkit-animation: animated_text 10s ease-in-out infinite; */
}

@keyframes animated_text {
	0% { background-position: 0px 50%; }
	/* 50% { background-position: 100% 50%; } */
	100% { background-position: 200% 50%; }
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.canvas3D {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  /* pointer-events: none; */
}

.slidingVertical{
	display: inline;
	text-indent: 8px;
}
/* .slidingVerticalTwo{
	text-indent: 8px;
} */
.slidingVertical span{
	animation: topToBottom 10s ease-in-out infinite 0s;
	-ms-animation: topToBottom 10s ease-in-out infinite 0s;
	-webkit-animation: topToBottom 10s ease-in-out infinite 0s;
	/* color: #00abe9; */

    background: linear-gradient(-45deg,    
       #FFE7A6,
      #863703,
      /* #1E0108,
      #001760, */
      #63B4EE,
      #FFEBB0);
    /* background-size: 200%; */
    /* font-family: Arial, Helvetica, sans-serif; */
    /* font-weight: 900; */
    /* font-size: 5vw; */
    letter-spacing: -1px;
    /* text-transform: uppercase; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* animation: animated_text 10s ease-in-out infinite;
    -moz-animation: animated_text 10s ease-in-out infinite;
    -webkit-animation: animated_text 10s ease-in-out infinite; */
  
	opacity: 0;
	overflow: hidden;
	position: absolute;
}
.slidingVertical span:nth-child(2){
	animation-delay: 2s;
	-ms-animation-delay: 2s;
	-webkit-animation-delay: 2s;
}
.slidingVertical span:nth-child(3){
	animation-delay: 4s;
	-ms-animation-delay: 4s;
	-webkit-animation-delay: 4s;
}
.slidingVertical span:nth-child(4){
	animation-delay: 6s;
	-ms-animation-delay: 6s;
	-webkit-animation-delay: 6s;
}
.slidingVertical span:nth-child(5){
	animation-delay: 8s;
	-ms-animation-delay: 8s;
	-webkit-animation-delay: 8s;
}

/*topToBottom Animation*/
@keyframes topToBottom{
	0% { opacity: 0; }
	5% { opacity: 0; -webkit-transform: translateY(-50px); }
	10% { opacity: 1; -webkit-transform: translateY(0px); }
	25% { opacity: 1; -webkit-transform: translateY(0px); }
	30% { opacity: 0; -webkit-transform: translateY(50px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
}
@-moz-keyframes topToBottom{
	0% { opacity: 0; }
	5% { opacity: 0; -moz-transform: translateY(-50px); }
	10% { opacity: 1; -moz-transform: translateY(0px); }
	25% { opacity: 1; -moz-transform: translateY(0px); }
	30% { opacity: 0; -moz-transform: translateY(50px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
}
@-webkit-keyframes topToBottom{
	0% { opacity: 0; }
	5% { opacity: 0; -webkit-transform: translateY(-50px); }
	10% { opacity: 1; -webkit-transform: translateY(0px); }
	25% { opacity: 1; -webkit-transform: translateY(0px); }
	30% { opacity: 0; -webkit-transform: translateY(50px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
}
@-ms-keyframes topToBottom{
	0% { opacity: 0; }
	5% { opacity: 0; -ms-transform: translateY(-50px); }
	10% { opacity: 1; -ms-transform: translateY(0px); }
	25% { opacity: 1; -ms-transform: translateY(0px); }
	30% { opacity: 0; -ms-transform: translateY(50px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
}

.neon {
  position: relative;
  overflow: hidden;
  filter: brightness(200%);
}

.textt {
  background-color: black;
  color: white;
  font-size: 180px;
  font-weight: bold;
  font-family: sans-serif;
  text-transform: uppercase;
  position: relative;
  user-select: none;
}

.textt::before {
  content: attr(data-text);
  position: absolute;
  color: white;
  filter: blur(0.02em);
  mix-blend-mode: difference;
}

.gradientt {
  position: absolute;
  background: linear-gradient(45deg, 
  #FFE7A6,
  #863703,
  /* #1E0108, */
  /* #001760, */
  #63B4EE,
  #FFEBB0
  );
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: multiply;
}

.spotlight {
  position: absolute;
  top: -100%;
  left: -100%;
  right: 0;
  bottom: 0;
  background: 
      radial-gradient(
          circle,
          white,
          transparent 25%
      ) center / 25% 25%,
      radial-gradient(
          circle,
          white,
          black 25%
      ) center / 12.5% 12.5%;
  animation: light 10s linear infinite;
  mix-blend-mode: color-dodge;
}

@keyframes light {
  to {
      transform: translate(50%, 50%);
  }
}

@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}

.sliderLogo {
  
  background: rgba(255, 255, 255, .2);
	height: 70px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: auto;
}
.slideTrackLogo {

  animation: scroll 40s linear infinite;
  display: flex;
  /* background-color: transparent; */
  width: calc(250px * 14);
}
.slideLogo {

  height: 100px;
  width: 250px;
}


.strikethrough {
	display: inline-block;
	position: relative;
	transition: all 0.5s cubic-bezier(.55, 0, .1, 1);
}

.strikethrough:after {
	content: '';
	position: absolute;
	display: block;
	width: 100%;
	height: 2px;
	box-shadow: 0 1px rgba(255,255,255,0.6);
	margin-top: -0.7em;
	background: black;
	transform-origin: center left;
	animation: strikethrough 1s 0.5s cubic-bezier(.55, 0, .1, 1) 1;
	transition: transform 0.5s cubic-bezier(.55, 0, .1, 1);
}


/* Little hover effect */

.strikethrough:hover {
	color: rgba(200,0,0,1);
	background: rgba(255, 189, 182, 0.3);
}

.strikethrough:hover:after {
	transform: scaleX(0);
	transform-origin: center right;
}




/* Keyframes for initial animation */

@keyframes strikethrough {
	from {
		transform: scaleX(0);
	}
	to {
		transform: scaleX(1);
	}
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.strikethrough {
  /* color: red; */
  position: relative;
}
.strikethrough:before {
  
  color: red;
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: red;
  
  -webkit-transform:rotate(-5deg);
  -moz-transform:rotate(-5deg);
  -ms-transform:rotate(-5deg);
  -o-transform:rotate(-5deg);
  transform:rotate(-5deg);
}

.priceLine{
  background-color: transparent;
  background-image: gradient(linear, 19.1% -7.9%, 81% 107.9%, color-stop(0, red), color-stop(.48, red), color-stop(.5,red), color-stop(.52, red), color-stop(1, red));
  background-image: repeating-linear-gradient(163deg, transparent 0%, transparent 48%, red 50%, transparent 52%, transparent 100%);
}

.strikediag {
  position: relative;
}
.strikediag:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 4px solid #850000!important;
  border-color: inherit;

  -webkit-transform:rotate(-25deg);
  -moz-transform:rotate(-25deg);
  -ms-transform:rotate(-25deg);
  -o-transform:rotate(-25deg);
  transform:rotate(-25deg);
}