@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
main,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: inter;
  color: black;
  background: #f0f0f0;
  overscroll-behavior: none;
}

.data {
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  opacity: 0;
  position: relative;
  display: grid;
  grid-template:
    'label .'
    'tag expl';
  align-items: end;
  line-height: 1em;
  gap: 1em;
  overflow: hidden;
  pointer-events: none;
  height: 12.5vw;
}

.data.show {
  opacity: 1;
}

.data > *:nth-child(1) {
  transition-delay: 0s;
  transform: matrix(1, 0, 0, 1, 0, 36);
}

.data > *:nth-child(2) {
  transition-delay: 0.1s;
  transform: matrix(1, 0, 0, 1, 0, 119);
}

.data > *:nth-child(3) {
  transition-delay: 0.2s;
  transform: matrix(1, 0, 0, 1, 0, 78);
}

.data > * {
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}

.data.show > * {
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.data > div {
  padding-top: 0.5em;
  width: auto;
  grid-area: label;
  position: relative;
  font-size: 1.5vw;
}

.data > div::after {
  transition: width 0.7s cubic-bezier(0.61, 1, 0.88, 1);
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0vw;
  height: 4px;
  background: black;
}

.data.show > div::after {
  width: 3.5vw;
}

h1 {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 7vw;
  line-height: 0.8em;
  letter-spacing: -0.05em;
}

.data > h1 {
  grid-area: tag;
}

.data > h2 {
  grid-area: expl;
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 1vw;
  line-height: 1.25em;
}

section {
    width: 100%;
    height: 100vh;
    /* border: 5px solid black; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }

/* .section-ten{

    z-index: 0 !important;
} */

  
.mask {
  height: 106px;
  position: relative;
  overflow: hidden;
  margin-top: var(--offset);
}

.mask span {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 100px;
  padding-bottom: var(--offset);
  
  background-size: 100% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-repeat: no-repeat;
}

.mask span[data-show] {
  transform: translateY(-100%);
  transition: .5s transform ease-in-out;
}

.mask span[data-up] {
  transform: translateY(-200%);
  transition: .5s transform ease-in-out;
}

.mask span:nth-child(1) {
  background-image: linear-gradient(45deg, #0ecffe 50%, #07a6f1);
}

.mask span:nth-child(2) {
  background-image: linear-gradient(45deg, #18e198 50%, #0ec15d);
}

.mask span:nth-child(3) {
  background-image: linear-gradient(45deg, #8a7cfb 50%, #633e9c);
}

.mask span:nth-child(4) {
  background-image: linear-gradient(45deg, #fa7671 50%, #f45f7f);
}


